jQuery(document).ready(function($){

    var enquirySuccess = $( '.enquiry-success-message' );
    enquirySuccess.hide();

    var enquiryError = $( '.enquiry-error-message' );
    enquiryError.hide();

    $('#enquiry-form').bootstrapValidator({
        feedbackIcons: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields: {
            name: {
                validators: {
                    notEmpty: {
                        message: 'Your name is required.'
                    }
                }
            },
            lastname: {
                validators: {
                    notEmpty: {
                        message: 'Your last name is required.'
                    }
                }
            },
            email: {
                validators: {
                    emailAddress: {
                        message: 'The email address seems to be wrong.'
                    },
                    notEmpty: {
                        message: 'Email is required.'
                    }
                }
            }
        }
    })
        .on('success.form.bv', function(e) {

            e.preventDefault();

            var form = $(this);

            var inputs = form.find('input, select, button, textarea');

            var serializedData = form.serialize();

            inputs.prop('disabled', true);

            $.ajax({
                url: '/enquiry',
                type: 'POST',
                headers : { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: serializedData,
                dataType: "json",
                timeout: 15000,
                success: function(response){
                    console.log(response);
                    switch(response.success){
                        case true:
                            enquirySuccess.append( '<p>Thank you for your enquiry, we will be in touch soon.</p>' );
                            enquirySuccess.show();
                            break;
                        case false:
                            $.each(response.errors, function( k, v ) {
                                enquiryError.append( '<p>'+v+'</p>' );
                            });
                            enquiryError.show();
                            inputs.prop('disabled', false);
                            break;
                        default:
                            inputs.prop('disabled', false);
                            console.log(response);
                            console.log('Unknown response');
                    }
                },
                error:function(response){
                    console.log('Failure');
                    console.log(response.responseText);
                    enquiryError.append( '<p>Error</p>' );
                    enquiryError.show();
                    $.each(response.responseText, function( k, v ) {
                        console.log(v);
                        enquiryError.append( '<p>'+v+'</p>' );
                    });
                    enquiryError.show();
                    inputs.prop('disabled', false);
                }
            });
        });


    $('#free-site-survey').bootstrapValidator({
            feedbackIcons: {
                valid: 'glyphicon glyphicon-ok',
                invalid: 'glyphicon glyphicon-remove',
                validating: 'glyphicon glyphicon-refresh'
            },
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: 'Your name is required.'
                        }
                    }
                },
                email: {
                    validators: {
                        emailAddress: {
                            message: 'The email address seems to be wrong.'
                        },
                        notEmpty: {
                            message: 'Email is required.'
                        }
                    }
                },
                phone: {
                    validators: {
                        notEmpty: {
                            message: 'Please enter your contact phone number'
                        }
                    }
                },
                "question['interested']": {
                    validators: {
                        notEmpty: {
                            message: 'Please select the number of titles you have'
                        }
                    }
                },
                "question['problems']": {
                    validators: {
                        notEmpty: {
                            message: 'Please select the your biggest problem'
                        }
                    }
                }
            }
        })
        .on('success.form.bv', function (e) {

            e.preventDefault();

            var form = $(this);

            var inputs = form.find('input, select, button, textarea');

            var serializedData = form.serialize();

            inputs.prop('disabled', true);

            $.ajax({
                url: '/form-submissions',
                type: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: serializedData,
                dataType: "json",
                timeout: 15000,
                success: function (response) {
                    console.log(response);
                    switch (response.success) {
                        case true:
                            enquirySuccess.append('<p>Thank you for your submission, we will be in touch soon.</p>');
                            enquirySuccess.show();
                            //if (typeof ga === "function") {
                            //    ga('send', 'event', 'bright', 'enquiry');
                            //}
                            break;
                        case false:
                            $.each(response.errors, function (k, v) {
                                enquiryError.append('<p>' + v + '</p>');
                            });
                            enquiryError.show();
                            inputs.prop('disabled', false);
                            break;
                        default:
                            inputs.prop('disabled', false);
                            console.log(response);
                            console.log('Unknown response');
                    }
                },
                error: function (response) {
                    console.log('Failure');
                    console.log(response.responseText);
                    enquiryError.append('<p>There was an error, please try again.</p>');

                    $.each(response.responseText, function (k, v) {
                        console.log(v);
                        enquiryError.append('<p>' + v + '</p>');
                    });
                    enquiryError.show();
                    inputs.prop('disabled', false);
                }
            });
        });

});